<template>
  <div>
    <b-alert
      v-if="isPreview"
      class="mb-3"
      show
      variant="primary"
    >
      <div class="alert-body">
        Vous visualisez actuellement agriprogress en tant que
        {{ farmHveAudit.farm.name }}
        <b-badge
          :to="mainRouteName"
          :pill="true"
          class="ml-1"
          variant="primary"
        >
          Quitter
        </b-badge>
      </div></b-alert>
    <b-alert
      v-if="!currentGesCulture && !currentHveCulture"
      show
      variant="danger"
    >
      <div class="alert-body">
        <feather-icon
          icon="AlertTriangleIcon"
          size="21"
        />
        Aucune culture n'est associée à cette exploitation.

      </div>
    </b-alert>
    <router-view
      v-if="farmHveAudit"
      :key="$route.fullPath"
    />
  </div>
</template>

<script>
import { BAlert, BBadge } from "bootstrap-vue";
import { mapState, mapGetters } from "vuex";
import {
  getUserData, isFarmer, isCoop, isAdmin
} from "@/auth/utils";
import sharedAuditsMixins from "@/mixins/sharedAudits";

export default {
  components: {
    BAlert,
    BBadge
  },
  mixins: [sharedAuditsMixins],
  data() {
    return {
      userData: getUserData()
    };
  },
  computed: {
    ...mapState("farm", { farmHveAudit: "hveAudit" }),
    ...mapState("verticalMenu", [
      "currentPeriod",
      "currentGesCulture",
      "currentHveCulture",
      "currentCooperative"
    ]),
    ...mapState("cooperative", { allCoopFarms: "allFarms" }),
    ...mapGetters("cooperative", { getCoopFarmById: "getFarmById" }),
    ...mapState("admin", { allAdminFarms: "allFarms" }),
    ...mapGetters("admin", { getAdminFarmById: "getFarmById" }),
    isPreview() {
      if (["Technician", "Admin"].includes(this.userData.type)) {
        return this.farmHveAudit !== null;
      }

      return false;
    },
    mainRouteName() {
      return isAdmin() ? { name: "admin-farms-list" } : { name: "coop-farms" };
    }
  },
  watch: {
    currentPeriod() {
      this.getFarmHveAudit();
      this.getAudits();
    },
    currentHveCulture() {
      if (this.currentCooperative) {
        this.getFarmHveAudit();
        this.getAudits();
      }
    },
    currentCooperative() {
      if (this.currentCooperative && this.currentHveCulture) {
        this.getFarmHveAudit();
        this.getAudits();
      }
    }
  },
  created() {
    if (
      !this.farmHveAudit
      || this.farmHveAudit.farm.id !== this.$route.params.farm_id
    ) {
      if (isFarmer()) {
        this.$store.commit("verticalMenu/SET_GES_CULTURES", this.userData.farm?.ges_cultures);
        this.$store.commit("verticalMenu/SET_GES_CULTURE", this.userData.farm?.default_culture);
        this.$store.commit("verticalMenu/SET_HVE_CULTURES", this.userData.farm?.hve_cultures);
        this.$store.commit("verticalMenu/SET_HVE_CULTURE", this.userData.farm?.default_culture);
        this.$store.commit(
          "verticalMenu/SET_COOPERATIVES",
          this.userData.farm.cooperatives
        );
        this.$store.commit(
          "verticalMenu/SET_COOPERATIVE",
          this.userData.farm.cooperatives[0]
        );
      }

      if (isCoop()) {
        if (this.allCoopFarms) {
          this.initCoop();
        } else {
          this.$http.getCooperativeFarms({ cooperative_id: this.userData.cooperative.id })
            .then(({ data }) => {
              this.$store.commit("cooperative/SET_ALLFARMS", data);
              this.initCoop();
            })
            .catch(console.warn);
        }

        this.$store.commit(
          "verticalMenu/SET_COOPERATIVE",
          this.userData.cooperative
        );
      }

      if (isAdmin()) {
        if (this.allAdminFarms) {
          this.initAdmin();
        } else {
          this.$http
            .ListFarms()
            .then(({ data }) => {
              this.$store.commit("admin/SET_ALLFARMS", data);
              this.initAdmin();
            })
            .catch(console.warn);
        }
      }
    }
  },
  methods: {
    initAdmin() {
      const currentFarm = this.getAdminFarmById(this.$route.params.farm_id);
      this.$store.commit("verticalMenu/SET_GES_CULTURES", currentFarm.ges_cultures);
      this.$store.commit("verticalMenu/SET_GES_CULTURE", currentFarm.default_culture);
      this.$store.commit("verticalMenu/SET_HVE_CULTURES", currentFarm.hve_cultures);
      this.$store.commit("verticalMenu/SET_HVE_CULTURE", currentFarm.default_culture);
      const { cooperatives } = this.getAdminFarmById(this.$route.params.farm_id);
      this.$store.commit("verticalMenu/SET_COOPERATIVES", cooperatives);
      this.$store.commit("verticalMenu/SET_COOPERATIVE", cooperatives[0]);
      this.getFarmHveAudit();
      this.getAudits();
    },
    initCoop() {
      const currentFarm = this.getCoopFarmById(this.$route.params.farm_id);
      this.$store.commit("verticalMenu/SET_GES_CULTURES", currentFarm.ges_cultures);
      this.$store.commit("verticalMenu/SET_GES_CULTURE", currentFarm.default_culture);
      this.$store.commit("verticalMenu/SET_HVE_CULTURES", currentFarm.hve_cultures);
      this.$store.commit("verticalMenu/SET_HVE_CULTURE", currentFarm.default_culture);
      this.getFarmHveAudit();
      this.getAudits();
    },
    addModulesRoutes() {
      this.farmHveAudit.option_a.indicator_groups.forEach(i => {
        // Avoid duplicated routes
        if (
          this.$router.getRoutes().some(r => r.name === `farm-hve-${i.slug}`)
        ) {
          return;
        }
        this.$router.addRoute("farm", {
          name: `farm-hve-${i.slug}`,
          path: `hve/modules/${i.slug}`,
          component: () => import("@/views/farm/hve/ModuleDetails.vue"),
          meta: {
            action: "read",
            resource: "farm"
          }
        });
      });
    },
    getFarmHveAudit() {
      this.$http
        .getFarmHveAudit({
          farm_id: this.$route.params.farm_id,
          period: this.currentPeriod,
          culture: this.currentHveCulture
        })
        .then(({ data }) => {
          this.$store.commit("farm/SET_HVEAUDIT", data);
          this.addModulesRoutes();
          this.$store.commit("verticalMenu/SET_FARM_NAVIGATION", data);
          if (this.$route.name === "farm-hve-modules") {
            this.$router
              .replace({ name: `farm-hve-${this.$route.params.slug}` })
              .catch(failure => console.info(`redirection to ${failure.to.path}`));
          }
        })
        .catch(console.warn);
    }
  }
};
</script>
